import Link from "next/link";
import React from "react";
import styled from "styled-components";
import Image from "next/image";

type IProps = {
    iconName: string;
    url: string;
    title: string;
};

const SocialMediaButton = ({
                               iconName,
                               url,
                               title,
                           }: IProps) => {

    return (
        <SocialMediaButtonWrapper key={Math.random()} href={url} target={'_blank'} rel={'noreferrer'} title={title}>
            <Image src={`/next/assets/icons/${iconName}.svg`} alt="" width={47} height={47}/>
        </SocialMediaButtonWrapper>
    )
}

const SocialMediaButtonWrapper = styled.a`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 47px;

  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
    box-shadow: 0 0 0 3px #FFFFFF;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 300ms;
  }

  &:hover {

    &:after {
      opacity: 1;
      transform: scale(1.15);
    }
  }
`;

export default SocialMediaButton;
