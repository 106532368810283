import {createGlobalStyle} from "styled-components";

type IProps = {
    bodyBackgroundColor?: boolean;
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props: IProps) => (props.bodyBackgroundColor ? 'white' : 'rgb(245 245 245 / 50%);')};
  }
`;


export default GlobalStyle;
