import React from "react";
import {QRCodeCanvas} from 'qrcode.react';

type IProps = {
    url: string;
}

const QrCodeButton = ({url}: IProps) => {
  return (
      <QRCodeCanvas value={url}  fgColor={'#fff'} bgColor={'transparent'}/>
  )
}

export default QrCodeButton;
