import Link from "next/link";
import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";
import {deviceMax} from "../../../../styles/device";


type IProps = {
    title : string;
    url : string;
    isActive? : boolean;
    fullWidth? : boolean;
    uppercase? : boolean;
    className? : string;
    mobileMenu? : boolean;
    dataTestId? : string;
};

const PinkButton = ({
    title,
    url,
    isActive = true,
    fullWidth = false,
    uppercase = false,
    className = '',
    mobileMenu = false,
                        dataTestId = ''
} : IProps)=>{

    return(
            <ButtonWrapper
                href={url}
                isActive={isActive}
                fullWidth={fullWidth}
                uppercase={uppercase}
                title={title}
                className={className}
                mobileMenu={mobileMenu}
                data-testid={dataTestId}
            >
                <span>
                    {title}
                </span>
            </ButtonWrapper>
    );

};

type IButtonWrapper = {
    isActive : boolean
    fullWidth : boolean
    uppercase : boolean
    mobileMenu : boolean
};

const ButtonWrapper = styled.a`
  display: ${(props: IButtonWrapper) => props.mobileMenu ? 'none' : 'block'};
  width: ${(props: IButtonWrapper) => props.fullWidth ? '100%' : 'fit-content'};
  background-color: ${(props: IButtonWrapper) => props.isActive ? Colors.brandColor : Colors.brandColorDisabled};
  text-transform: ${(props: IButtonWrapper) => props.uppercase ? 'uppercase' : 'none'};
  color: ${Colors.white};
  padding: 12px 32px;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  text-decoration: none;
  
  &.registerButton.career_page {
    @media ${deviceMax.tablet} {
        display: none;
    }
  }

  &:hover {
    background-color: ${(props: IButtonWrapper) => props.isActive ? Colors.brandColorHover : Colors.brandColorDisabled};
    color: ${Colors.white};
  }

  @media ${deviceMax.tablet}{
    display: ${(props: IButtonWrapper) => props.mobileMenu ? 'block' : 'none'};
    width: fit-content;
    background-color: ${(props: IButtonWrapper) => props.isActive ? Colors.blackColor : Colors.brandColor};
    color: ${Colors.white};
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    border-radius: 36px;
    padding: 8px 16px;
    &:hover {
      background-color: ${Colors.brandColor};
      color: ${Colors.white};
    }
  }
`;

export default PinkButton;
