import Link from "next/link";
import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

type IProps = {
    title: string;
    url: string;
    isHover?: boolean;
    uppercase?: boolean;
    lineHeight?: boolean;
}

const TextButton = ({
                        title,
                        url,
                        isHover = true,
                        uppercase = false,
                        lineHeight = true
                    }: IProps) => {

    return (
        <ButtonWrapper href={url} isHover={isHover}
                       uppercase={uppercase} lineHeight={lineHeight}>
                <span>
                    {title}
                </span>
        </ButtonWrapper>
    )

}

type IButtonWrapper = {
    isHover: boolean
    uppercase: boolean
    lineHeight: boolean
}

const ButtonWrapper = styled.a`
  display: block;
  width: fit-content;
  text-transform: ${(props: IButtonWrapper) => props.uppercase ? 'uppercase' : 'none'};
  color: ${Colors.white};
  font-weight: 400;
  font-size: 20px;
  line-height: ${(props: IButtonWrapper) => props.lineHeight ? '40px' : '25px'};
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    ${(props: IButtonWrapper) => props.isHover ? 'transform: scale(1.05);' : ''};
  }
`;

export default TextButton;
