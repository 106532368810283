import React from "react";
import Head from "next/head";
import NextSeoComp from "../nextSeo/NextSeoComp";

type IProps = {
    pageTitle?: string;
    noIndex?: boolean;
    description?: string;
    canonical?: boolean;
};


const HtmlHead = ({
                      pageTitle = 'Pembe Panjur',
                      noIndex = false,
                      description = 'Üyelerini bilimsel kişilik testi ve sosyo-ekonomik yaşam analizi sonucunda eşleştiren, bütün profillerin gizli olduğu ciddi ilişki ve evlilik sitesi.',
                      canonical = true,
                  }: IProps) => {
    return (
        <React.Fragment>
            <Head>
                <title>{pageTitle}</title>
                <link rel="icon" href="/next/favicon.png"/>
                <meta name="viewport"
                      content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={'anonymous'}/>
            </Head>
            <NextSeoComp pageTitle={pageTitle} noIndex={noIndex} description={description} canonical={canonical}/>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
      window.fbAsyncInit = function() {
        FB.init({
            appId: ${process.env.NEXT_PUBLIC_FACEBOOK_ID},
            autoLogAppEvents: true,
            version: 'v16.0'
        });
          
        FB.AppEvents.logPageView();   
          
      };
        
      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "https://connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
    `,
                }}
            />
        </React.Fragment>

    );
};

export default HtmlHead;
