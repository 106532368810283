import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";

export const HeaderComp = styled.div`

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  padding-top: 32px;
  padding-left: calc(50vw - 575px);
  padding-right: calc(50vw - 575px);
  box-sizing: border-box;
  
  @media ${deviceMax.tablet}{
    padding-top: 22px;
  }
  
  >.wrapper{
    position: relative;
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    >a.loginButton-hidden{
      margin-left: 40px;
      @media ${deviceMax.tablet}{
        display: none;
      }
      &:first-child{
        margin-left: 0;
        @media ${deviceMax.tablet}{
          display: block;
        }
      }

      &.career_page {
        @media ${deviceMax.tablet} {
          display: block;
        }
      }
    }
    
  }
`;

type ILogoComp = {
    menuOpen: boolean;
}

export const LogoComp = styled.a`
  display: flex;
  margin-right: auto;
  flex: 0 auto;
  width: 200px;
  z-index: 101;
  position: ${(props:ILogoComp)=>props.menuOpen ? 'fixed' : 'relative'};
  left: ${(props:ILogoComp)=>props.menuOpen ? '25px' : 'unset'};
  top: ${(props:ILogoComp)=>props.menuOpen ? '52px' : 'unset'};
  >img{
    width: 200px;
  }
  @media ${deviceMax.tablet}{
    width: 126px;
    >img{
      width: 100%;
      height: auto;
    }
  }
`;

type ISloganComp = {
    backgroundType : 'dark' | 'light';
};
export const SloganComp = styled.div `
  display: flex;
  flex: 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  @media ${deviceMin.tablet} and ${deviceMax.laptop}{
    margin-left: 20px;
  }
  
  @media ${deviceMax.tablet}{
    display: none;
  }
  
  >span{
    flex: 0 auto;
    color: ${(props:ISloganComp)=>props.backgroundType === 'dark' ? Colors.white : Colors.black};
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
  }
  >div{
    flex: 0 auto;
    margin: 0 20px;
    top: -7px;
  }
`;

type IHamburgerMenuButtonComp = {
    backgroundType : 'black' | 'white';
    menuOpen : boolean;
};
export const HamburgerMenuButtonComp = styled.div<IHamburgerMenuButtonComp> `
    flex: 0 auto;
    width: 18px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 101;
  position: ${(props)=>props.menuOpen ? 'fixed' : 'relative'};
    right: ${(props)=>props.menuOpen ? '25px' : 'unset'};
  top: ${(props:ILogoComp)=>props.menuOpen ? '62px' : 'unset'};
    //display: none;
    >span{
      background-color: ${(props)=> props.backgroundType === 'white' && !(props.menuOpen) ? Colors.white : Colors.black};
      width: 18px;
      height: 2px;
      transform-origin: 1px;
      transition: all 0.3s linear;
      &:nth-child(2){
        width: 14px;
        margin: 5px 0;
      }
    }

  .burger1 {
    transform: ${(props) => props.menuOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }

  .burger2 {
    transform: ${(props) => props.menuOpen ? 'translateX(100%)' : 'translateX(0)'};
    opacity: ${(props) => props.menuOpen ? 0 : 1};
  }

  .burger3 {
    transform: ${(props) => props.menuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    position: relative;
    top: ${(props) => props.menuOpen ? '-2px' : 'auto'};
  }
  
  @media ${deviceMin.tablet} {
    display: none;
  }
  
`;

type IHamburgerMenuLinksComp = {
    menuOpen: boolean;
}
export const HamburgerMenuLinkComp = styled.div`
  display: ${(props: IHamburgerMenuLinksComp) => props.menuOpen ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${Colors.white};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  padding: 0 25px;
  box-sizing: border-box;
  transition: all 0.3s linear;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    
    > a {
      color: ${Colors.black};
    }
  }
`;
