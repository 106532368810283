import React from "react";
import type {NextPage} from "next";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Image from "next/image";
import {deviceMax, deviceMin} from "../../../styles/device";

const HowIsItWorking = () => {
    return (
        <HowIsItWorkingWrapper>
            <h3>
                Nasıl çalışıyor?
            </h3>
            <h2>
                10 binlerce çifti mutlu sona ulaştırdık
            </h2>
            <p>
                Üyelerine 12 yıldan bu yana hizmet veren Pembe Panjur servisleriyle klasik evlilik sitelerinden ayrışır.
                Öncelikli hedefi mutlu, uzun soluklu ve sağlıklı birliktelik yaşayabilecek ciddi ilişki niyetindeki
                üyeleri birbirleriyle tanıştırmaktır.
            </p>
            <p className="bold">
                Pembe Panjur'un farkı;
            </p>
            <ul>
                <li>
                    <InlineHeartComp/>
                    Üyeleri arasında hem sosyo-ekonomik hem de kişilik testi sonuçlarına dayalı eşleştirme yapması,
                </li>
                <li>
                    <InlineHeartComp/>
                    Sadece birbirleriyle eşleştirilmiş üyelerin birbirlerini görebilmesini sağlaması,
                </li>
                <li>
                    <InlineHeartComp/>
                    Üyelerinin mahremiyetini ve güvenliğini üst düzeyde koruması ve…
                </li>
                <li>
                    <InlineHeartComp/>
                    Üyelerinin hassasiyetlerini dikkate almasıdır.
                </li>
            </ul>

        </HowIsItWorkingWrapper>
    )
}

const HowIsItWorkingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 0;
  
  @media ${deviceMax.tablet} {
    padding: 30px 24px 10px;
  }
  
  @media ${deviceMax.laptopL} {
    padding-left: 24px;
    padding-right: 24px;
  }

  > h3 {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: uppercase;
    color: ${Colors.brandColor};
    margin-top: 0;
    margin-bottom: 20px;
    
    @media ${deviceMax.tablet} {
      margin-bottom: 16px;
      font-size: 14px;
    }
  }

  > h2 {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 70px;
    letter-spacing: 0;
    color: ${Colors.blackColor};
    margin-bottom: 25px;
    margin-top: 0;
    
    @media ${deviceMax.laptop} {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
    
    @media ${deviceMin.laptop} and ${deviceMax.laptopS} {
      font-size: 30px;
      line-height: 50px;
    }
  }

  > p {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0;
    color: ${Colors.blackColor};
    margin-bottom: 30px;
    margin-top: 0;

    @media ${deviceMax.tablet} {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &.bold {
      font-weight: 800;
    }
  }

  > ul {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media ${deviceMax.laptop} {
      align-items: stretch;
    }

    > li {
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0;
      color: ${Colors.blackColor};
      width: fit-content;
      margin: 0;
      
      @media ${deviceMax.laptop} {
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        padding-left: 35px;
        margin-bottom: 16px;
        
        &:last-child {
          margin-bottom: 0;
        }
      }

      


    }
  }
`;

type IInlineHeartComp = {
    marginCorrection? : boolean;
    topCorrection? : boolean;
}
export const InlineHeartComp = styled.div `
  
    position: relative;
    width: 50px;
    overflow: inherit;
    list-style: none;
    -webkit-animation: animateHeart 2.5s infinite;
    animation: animateHeart 2.5s infinite;
    margin-left: ${(props:IInlineHeartComp)=>props.marginCorrection ? '-50px' : '0'};
    top: 5px;
    
    @media ${deviceMax.tablet} {
      margin-left: ${(props:IInlineHeartComp)=>props.marginCorrection ? '-50px' : '0'};
      top: ${(props:IInlineHeartComp)=>props.marginCorrection ? '5px' : '0'};
    }
    
    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 21px;
      text-indent: 0;
      font-variant: normal;
      line-height: 21px;
    }
    
    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      width: 12px;
      height: 20px;
      background: ${Colors.brandColor};
      border-radius: 100px 100px 0 0;
      -webkit-transform: rotate(-45deg) translateZ(0);
      transform: rotate(-45deg) translateZ(0);
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
    }
    
    &:after {
      left: 26%;
      -webkit-transform: rotate(45deg) translateZ(0);
      transform: rotate(45deg) translateZ(0);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

  @-webkit-keyframes animateHeart {
    0% {
      -webkit-transform: scale(0.8);
    }
    5% {
      -webkit-transform: scale(0.9);
    }
    10% {
      -webkit-transform: scale(0.8);
    }
    15% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(0.8);
    }
  }
  @keyframes animateHeart {
    0% {
      transform: scale(0.8);
    }
    5% {
      transform: scale(0.9);
    }
    10% {
      transform: scale(0.8);
    }
    15% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
InlineHeartComp.defaultProps = {
    marginCorrection : true,
    topCorrection : true
};

export default HowIsItWorking;
