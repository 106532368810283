import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";

const AllRightsReserved = () => {

    const [formattedDate, setFormattedDate] = useState<string>('');
    const [currentYear, setCurrentYear] = useState<number>(0);

    useEffect(() => {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        setCurrentYear(now.getFullYear());

        const timeUntilNextDay = nextDay.getTime() - now.getTime();

        const updateDate = () => {
            const newDate = new Date();
            const formatted = newDate.toLocaleDateString('tr-TR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
            setFormattedDate(formatted);
        };

        const updateInterval = setInterval(updateDate, timeUntilNextDay);

        updateDate();

        return () => clearInterval(updateInterval);
    }, []);

    return (
        <React.Fragment>
            <AllRightsReservedWrapper>
                <div className={'all-rights-reserved'}>
                    2010 - {currentYear} © PEMBE PANJUR. TÜM HAKLARI SAKLIDIR. <span>|</span> {formattedDate}
                </div>
            </AllRightsReservedWrapper>
        </React.Fragment>
    );
};

const AllRightsReservedWrapper = styled.div`
  .all-rights-reserved {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: ${Colors.white};
    
    @media ${deviceMax.tablet} {
      line-height: 30px;
      text-align: center;
    }

    @media ${deviceMin.tablet} and ${deviceMax.laptopL} {
      font-size: 12px;
    }
    
    > span {
      margin: 0 30px;
    }
  }
`;

export default AllRightsReserved;
