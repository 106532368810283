import Link from "next/link";
import React from "react";
import {NavigationURLs} from "../../functions/NavigationFunctions";
import {HamburgerMenuButtonComp, HamburgerMenuLinkComp, HeaderComp, LogoComp, SloganComp} from "./HeaderStyledComponents";
import PinkButton from "../buttons/pinkButton/PinkButton";
import {InlineHeartComp} from "../howIsItWorking/HowIsItWorking";
import TextButton from "../buttons/textButton/TextButton";
import {useAmp} from "next/amp";
import Image from "next/image";

export const config = {amp: 'hybrid'}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [elemName: string]: any;
        }
    }
}

type IHeaderProps = {
    headerBackground? : 'light' | 'dark';
    loginButton? : boolean;
    registerButton? : boolean;
    homeButton? : boolean;
    sloganOne? : string;
    sloganTwo? : string;
    hamburgerMenuType? : 'white' | 'black';
    hamburgerMenu? : boolean;
    loginButtonMenu? : boolean;
    mobileMenu? : boolean;
    className? : string;
    bgColor? : boolean;
    accountDelete? : boolean;
};

const headerLogoUrls = {
    black : '/next/assets/images/logos/pembe-panjur-logo-black.png',
    white : '/next/assets/images/logos/pembe-panjur-logo-white.svg',
    pink : '/next/assets/images/logos/pembe-panjur-logo-pink.svg'
};

const Header = ({
    headerBackground = 'light',
    loginButton = false,
    registerButton = false,
    homeButton = false,
    sloganOne = '',
    sloganTwo = '',
    hamburgerMenuType = 'white',
    hamburgerMenu = false,
    loginButtonMenu = false,
                    className = '',
    bgColor = false,
                    accountDelete = false
}: IHeaderProps) => {

    const isAmp = useAmp();

    const [menuOpen, setMenuOpen] = React.useState(false);

    const toggleMenu = ()=>{
        setMenuOpen(!menuOpen);
    }

    return (
        <HeaderComp style={bgColor ? {backgroundColor: '#fff'} : {}}>
            <div className={'wrapper'}>

                <LogoComp data-testid='ppLogo' menuOpen={menuOpen} href={NavigationURLs.landing}>
                    {isAmp ? (
                        <amp-img
                            src={(headerBackground === 'dark' && !menuOpen) ? headerLogoUrls.white : headerLogoUrls.pink}
                            alt={'Pembe Panjur Logo'}
                            width={200}
                            height={56}
                            layout={'fixed'}
                        />
                    ) : (
                        <Image
                            src={(headerBackground === 'dark' && !menuOpen) ? headerLogoUrls.white : headerLogoUrls.pink}
                            loading={(headerLogoUrls.pink) ? 'eager' : 'lazy'} width={200} height={56}
                            priority={!!(headerLogoUrls.pink)}
                            alt={'Pembe Panjur Logo'}
                        />
                    )}

                </LogoComp>

                {(sloganOne !== '' || sloganTwo !== '') ? (
                    <SloganComp
                        backgroundType={headerBackground}
                    >
                        {(sloganOne !== '') ? (
                            <span>
                                {sloganOne}
                            </span>
                        ) : null}
                        {(sloganOne !== '' && sloganTwo !== '') ? (
                            <InlineHeartComp marginCorrection={false}/>
                        ) : null}
                        {(sloganTwo !== '') ? (
                            <span>
                                {sloganTwo}
                            </span>
                        ) : null}
                    </SloganComp>
                ) : null}

                {(loginButton) ? (
                    <PinkButton className={'loginButton-hidden'}
                        title={(headerBackground === 'dark') ? 'Üye Giriş' : 'Giriş Yap'}
                        url={NavigationURLs.login}
                        uppercase={true}
                                dataTestId={'loginButton'}
                    />
                ) : null}
                {(registerButton) ? (
                    <PinkButton className={'loginButton-hidden'}
                        title={'Hemen Üye Ol'}
                        url={NavigationURLs.register}
                        uppercase={true}
                                dataTestId={'registerButton'}
                    />
                ) : null}
                {(homeButton) ? (
                    <PinkButton className={`loginButton-hidden ${className}`}
                        title={'Anasayfa'}
                        url={NavigationURLs.landing}
                        uppercase={true}
                    />
                ) : null}

                {(hamburgerMenu) ? (
                    <React.Fragment>
                        <HamburgerMenuLinkComp menuOpen={menuOpen}>
                            <div>
                                <TextButton title={"Hakkında"} url={NavigationURLs.about}/>
                                <TextButton title={"Yardım"} url={NavigationURLs.support}/>
                                <TextButton title={"Kariyer"} url={NavigationURLs.career}/>
                                <TextButton title={"Gizlilik Sözleşmesi"} url={NavigationURLs.privacy}/>
                                <TextButton title={"Üyelik Sözleşmesi"} url={NavigationURLs.membership}/>
                                <TextButton title={"İletişim"} url={NavigationURLs.contact}/>
                                <TextButton title={"Hikayeni Gönder"} url={NavigationURLs.successStorySend}/>
                            </div>
                        </HamburgerMenuLinkComp>

                        <HamburgerMenuButtonComp
                            backgroundType={hamburgerMenuType}
                            menuOpen={menuOpen}
                            onClick={toggleMenu}
                        >
                            <span className={'burger1'}></span>
                            <span className={'burger2'}></span>
                            <span className={'burger3'}></span>
                        </HamburgerMenuButtonComp>
                    </React.Fragment>
                ) : (
                    (accountDelete) ? null :(
                        (loginButtonMenu) ? (
                            <PinkButton
                                title={'Üye Giriş'}
                                url={NavigationURLs.login}
                                uppercase={true}
                                mobileMenu={true}
                                isActive={false}
                                dataTestId={'loginButton'}
                            />
                        ) : (
                            <PinkButton
                                title={'Hemen Üye Ol'}
                                url={NavigationURLs.register}
                                uppercase={true}
                                mobileMenu={true}
                                isActive={true}
                                className={`registerButton ${className}`}
                                dataTestId={'registerButton'}
                            />
                        )
                    )
                )}


            </div>
        </HeaderComp>
    );
}


export default Header;
