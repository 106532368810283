import styled from "styled-components";
import Colors from "../constants/Colors";
import {deviceMax} from "../../styles/device";

type IHeroArea = {
    backgroundImageMobile? : string;
    backgroundImageDesktop? : string;
};

export const GeneralPage = styled.div `
  width: 100vw;
  height: auto;
  min-height: 100vh;
  
  
  .subHeroArea{
    position: relative;
    width: 100vw;
    box-sizing: border-box;
    background: linear-gradient(180deg, #D2147D 0%, #75227E 76.56%, #541D98 99.99%, #D2147D 100%);
    padding: 28px 24px 35px 24px;
    
    @media ${deviceMax.tablet}{
      padding-top: 30px;
      padding-bottom: 30px;
    }
    
  }
  .content{
    position: relative;
    width: 100vw;
    box-sizing: border-box;
  }
  
`;

export const LandingHeroArea = styled.div `
    position: relative;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: no-repeat center center;
    background-size: cover;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    background-image: url(${(props : IHeroArea) => props.backgroundImageDesktop ?? ''});
  
    padding-bottom: 20px;
  
    @media ${deviceMax.tablet} {
      background-image: url(${(props : IHeroArea) => props.backgroundImageMobile ?? ''});
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    
    
    .linearGradient{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 50%);
    }
`;

export const SubpageLandingHeroArea = styled.div `
  position: relative;
  margin-top: 115px;
`;

type IWaveArea = {
    backgroundColor? : string;
    backgroundType? : 'linear' | 'none';
}
export const WaveArea = styled.div `

  position: relative;
  width: 100%;
  padding: 0 calc(50% - 575px);
  margin-top: 115px;
  box-sizing: border-box;
  //overflow: hidden;
    background: ${(props : IWaveArea) => props.backgroundType === 'linear' ? props.backgroundColor : 'none'};
  
  >.wave{
    display: none;
  }
  >.wrapper{
    position: relative;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
  }

  @media ${deviceMax.tablet} {
    padding: 56px 0 0;
    margin-top: 0;
    background: ${(props : IWaveArea) => props.backgroundColor ?? '#F0F0F0'};
    >.wave{
      display: block;
    }
    >.wrapper{
      background: none;
      padding:0 24px;
    }
  }
`;
