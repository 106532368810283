import React from 'react';
import {NextSeo} from "next-seo";
import { useRouter } from 'next/router';

type IProps = {
    pageTitle?: string;
    noIndex?: boolean;
    description?: string;
    canonical?: boolean;
}

const NextSeoComp = (props: IProps) => {

    const router = useRouter();

    let canonicalUrl = '';

    const origin =
        typeof window !== 'undefined' && window.location.origin
            ? window.location.origin
            : '';

    let routerPath = router.asPath;

    if (routerPath === '/'){
        canonicalUrl = origin;
    } else {
        canonicalUrl = routerPath;
    }

    return (
        <React.Fragment>
            <NextSeo
                title={props.pageTitle}
                description={props.description}
                {...props.canonical && {canonical: canonicalUrl} }
                openGraph={{
                    url: 'https://www.pembepanjur.com/',
                    title: props.pageTitle,
                    description: props.description,
                    images: [
                        {
                            url: '/next/assets/images/seo/facebook_logo2.jpeg',
                            width: 800,
                            height: 600,
                            alt: 'Pembe Panjur Türkiye\'nin En Ciddi Evlilik Sitesi.',
                            type: 'image/jpeg',
                        }
                    ],
                    siteName: 'Pembe Panjur',
                    type: 'website',
                }}
                facebook={{
                    appId: '174812212583480',
                }}
                noindex={props.noIndex}
                nofollow={props.noIndex}
            />
        </React.Fragment>

    );
};

export default NextSeoComp;
